import React, { ComponentType } from "react";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { PageProps } from "@/pages/_app";
import { userAtom } from "@/store/global";

export type Props = ReturnType<any>;

export function withContainer(WrappedComponent: ComponentType<Props>) {
  return function Page(props: PageProps) {
    const [user] = useAtom(userAtom);
    const router = useRouter();
    if (user) {
      router.push("/");
    }

    return <WrappedComponent {...props} />;
  };
}

import Head from "next/head";
import { memo } from "react";
import { withContainer, Props } from "./container";
import LoginForm from "@feature/login/login-form";

function LoginPresenter(props: Props) {
  return (
    <>
      <Head>
        <title>로그인</title>
      </Head>
      <div>
        <section className="h-screen">
          <LoginForm {...props} />
        </section>
      </div>
    </>
  );
}

export default withContainer(memo(LoginPresenter));

import { PropsWithChildren, ReactNode } from "react";
import { TextBody, CheckLineRegular } from "@greenlabs/formula-components";

interface Props {
  handleChecked: (isCheck: boolean) => void;
  isChecked: boolean;
  right?: ReactNode;
  children: ReactNode;
}

const FCheckbox = ({ handleChecked, isChecked, right, children }: PropsWithChildren<Props>) => {
  return (
    <label className="flex items-start text-base">
      <div className="flex items-center">
        <button
          className={`w-6 h-6 border rounded-md flex justify-center items-center ${isChecked && "bg-[#0BB25F]"}`}
          onClick={() => handleChecked(!isChecked)}
          type="button"
        >
          <CheckLineRegular size="sm" color={isChecked ? "white" : "gray-30"} />
        </button>
        <TextBody className="ml-2" size="md" weight="regular" color="neutral-primary-contents">
          {children}
        </TextBody>
      </div>
      {right}
    </label>
  );
};

export default FCheckbox;

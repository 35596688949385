import { useEffect, useState, memo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { useSetAtom } from "jotai";
import { TextHeadline, TextField, ContainerButton, Divider, TextCaption } from "@greenlabs/formula-components";

import jwtService from "@service/jwt-service";
import { setUserData } from "@feature/auth";
import { CLIENT, ROUTE } from "@/constants";
import { userAtom } from "@/store/global";
import { withAuth } from "@feature/auth";
import FCheckbox from "@components/common/checkbox/FCheckbox";

const conStr = {
  COOKIE_USER_NAME: "vhows_login_username",
  COOKIE_GOOGLE_LOGIN_ERROR: "googleLoginError",
};

interface LoginField {
  username: string;
  password: string;
}

const GoogleIcon = () => {
  return <Image src={`/button/google-icon.png`} width={23} height={23} alt={"구글 아이콘"} />;
};

const LoginForm = () => {
  const router = useRouter();
  const [field, setField] = useState<LoginField>({
    username: "",
    password: "",
  });
  const [idCheckBox, setIdCheckBox] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const setUser = useSetAtom(userAtom);

  useEffect(() => {
    if (localStorage.getItem(conStr.COOKIE_USER_NAME)) {
      setField(prev => ({ ...prev, username: localStorage.getItem(conStr.COOKIE_USER_NAME) }));
      setIdCheckBox(true);
    }
    if (localStorage.getItem(conStr.COOKIE_GOOGLE_LOGIN_ERROR)) {
      const googleErrorMessage =
        localStorage.getItem(conStr.COOKIE_GOOGLE_LOGIN_ERROR) === "LOGIN-E201"
          ? "(주)그린랩스 계정이 아닙니다."
          : `구글계정 로그인에 실패하였습니다. 에러코드: ${localStorage.getItem(conStr.COOKIE_GOOGLE_LOGIN_ERROR)}`;
      setErrorMessage(`${googleErrorMessage}`);
      localStorage.removeItem(conStr.COOKIE_GOOGLE_LOGIN_ERROR);
    }

    // 구글 로그인 관련 로직
    const accessToken =
      router.asPath.indexOf("accessToken") > -1
        ? router.asPath.split("accessToken=")[1].split("&refreshToken=")[0]
        : undefined;
    const refreshToken =
      router.asPath.indexOf("accessToken") > -1
        ? router.asPath.split("accessToken=")[1].split("&refreshToken=")[1]
        : undefined;

    // 로그인 후 리다이렉트
    if (accessToken && refreshToken) {
      jwtService.setToken(accessToken);
      jwtService.setRefreshToken(refreshToken);

      setUserData().then(res => {
        // @ts-ignore
        setUser(res);
        const redirect = localStorage.getItem(CLIENT.REDIRECT);
        if (redirect && redirect !== "/login") {
          localStorage.removeItem(CLIENT.REDIRECT);
          router.replace(redirect);
        } else {
          router.replace(ROUTE.DASHBOARD, undefined, { shallow: true });
        }
      });
    }
  }, [router, setUser]);

  const onChange = (event: any, type: string) => {
    setField(prev => ({ ...prev, [type]: event.target.value }));
  };

  const login = () => {
    jwtService
      .login({
        username: field.username,
        password: field.password,
      })
      .then(response => {
        if (idCheckBox) {
          localStorage.setItem(conStr.COOKIE_USER_NAME, field.username);
        } else {
          localStorage.removeItem(conStr.COOKIE_USER_NAME);
        }
        jwtService.setToken(response.data.accessToken);
        jwtService.setRefreshToken(response.data.refreshToken);
      })
      .then(() => {
        router.push(ROUTE.DASHBOARD);
      })
      .catch(error => {
        const defaultMessage = "아이디 또는 비밀번호가 일치하지 않습니다.";
        let errMsg: string = "";
        if (error.response !== undefined) {
          const erd = error.response.data;
          switch (erd.code) {
            case "LOGIN-E100": // 아이디 존재하지 않음
              errMsg = "아이디가 존재하지 않습니다.";
              break;
            case "LOGIN-E101": // 아이디 잠금
              errMsg = `계정이 잠겨 있습니다. ${erd.errorData.afterTimeSec}초 후에 다시 시도해주세요.`;
              break;
            case "LOGIN-E102": // 패스워드 오류
              errMsg = `아이디 또는 비밀번호가 일치하지 않습니다. ${erd.errorData.loginFailLimit}회 이상 잘못 입력하실 경우, ${erd.errorData.loginLockMinutes}분후에 다시 시도 하실 수 있습니다. (${erd.errorData.loginFailCount} / ${erd.errorData.loginFailLimit})`;
              break;
            case "LOGIN-E103": // 계정 비활성화
              errMsg = "계정이 비활성화 되어있습니다.";
              break;
            case "LOGIN-E104": // 계정 만료 체크
              errMsg = "아이디가 존재하지 않습니다.";
              break;
            case "LOGIN-E105": // 패스워드 만료
              errMsg = "만료된 패스워드 입니다.";
              break;
            case "LOGIN-E106": // 신청 완료, 검토 중
              errMsg = "가입 승인 검토중 입니다.";
              break;
            case "LOGIN-E107": // 승인 거절
              errMsg = "가입 승인이 거절 되었습니다. 고객센터로 문의바랍니다.";
              break;
            case "LOGIN-E108": // 사용 중단
              errMsg = "서비스 사용이 중단 되었습니다. 고객센터로 문의바랍니다.";
              break;
            default:
              errMsg = defaultMessage;
              break;
          }
        } else {
          errMsg = defaultMessage;
        }
        setErrorMessage(errMsg);
      });
  };

  const googleLogin = () => {
    location.href = `${process.env.NEXT_PUBLIC_BASE_URL}/oauth2/authorization/google?redirect_uri=${location.protocol}//${location.host}/oauth/redirect`;
  };

  return (
    <>
      <div className="flex h-full flex-wrap items-center justify-center">
        <div className="min-w-[360px] px-4">
          <div className="mb-10 flex justify-center text-center">
            <Link href={"/"} passHref={true}>
              <a className="flex items-center">
                <Image src={"/logo.png"} width={27} height={30} alt={"로고"} />
                <TextHeadline className="ml-1.5" color="primary-contents" size="lg" weight="bold">
                  브이하우스
                </TextHeadline>
              </a>
            </Link>
          </div>

          {errorMessage !== "" && (
            <div className={"mb-4 rounded border border-red-400 bg-red-200 py-2 px-4 text-sm text-white"}>
              {errorMessage}
            </div>
          )}

          <div className="mb-4" data-cy="login-id">
            <TextField
              placeholder="아이디"
              value={field.username}
              onChange={e => {
                onChange(e, "username");
              }}
            ></TextField>
          </div>

          <div className="mb-4" data-cy="login-password">
            <TextField
              type="password"
              placeholder="비밀번호"
              value={field.password}
              onChange={(e: any) => {
                onChange(e, "password");
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  login();
                }
              }}
            ></TextField>
          </div>

          <div className="mb-6">
            <FCheckbox handleChecked={isCheck => setIdCheckBox(isCheck)} isChecked={idCheckBox}>
              아이디 저장하기
            </FCheckbox>
          </div>

          <div className="text-center">
            <ContainerButton
              block={true}
              data-cy="login-button"
              color="primary"
              size="lg"
              disabled={!field.username || !field.password}
              text="로그인"
              onClick={() => login()}
            />

            <Divider variant="small" className="mt-10 mb-6" />

            <div className="mb-6">
              <TextCaption color="gray-80">
                (주)그린랩스 계정의 경우에만
                <br />
                `구글 계정으로 로그인`이 가능합니다.
              </TextCaption>
            </div>

            <ContainerButton
              color="secondary-gray"
              block={true}
              size="lg"
              text="구글 계정으로 로그인"
              leftIcon={GoogleIcon}
              onClick={() => googleLogin()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(memo(LoginForm));
